module.exports = [{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/src/cms/cms.js"},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-118641437-2"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/desmond/Desmond Files/desmond-web-projects/desmond-personal-site/front/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
